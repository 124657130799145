export function fetchShipmentSettingsPageData(){
    const {get:fetchShipment}=useOFetchCustom(`settings/generals/shipment`)

    const shipmentData = useShipmentSettignsData()

    async function fetch() {
        return new Promise((resolve, reject)=>{
            fetchShipment(undefined,{
                onSuccess:(response:any)=>{
                    console.log(response.data)
                    shipmentData.value=response.data
                    resolve(resolve)
                },
                onError:(error:any)=>{
                    reject(error)
                }
            })
        })
    }
    return {fetch, shipmentData}

}

export default defineNuxtRouteMiddleware(async (to, from)=>{
    const {fetch} = fetchShipmentSettingsPageData()

    try {
        await fetch()
    }
    catch(error:any){
        return navigateTo('/store')
    }
})